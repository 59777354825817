





























































































































import {
    Component,
    Vue,
	Watch
} from 'vue-property-decorator';
import {
    getComponent
} from '@/utils/helpers';
import wellTestModule from '@/store/modules/wellTestModule';
import accountModule from '@/store/modules/accountModule';
import assetsModule from '@/store/modules/assetsModule';
import scheduleModule from '@/store/modules/scheduleModule';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import { mixins } from 'vue-class-component';
import Multiselect from 'vue-multiselect';
import { Debounce } from 'vue-debounce-decorator';
import { DateTime } from 'luxon';


import workspaceModule from '@/store/modules/workspaceModule';
import AWS from 'aws-sdk';

@Component({
    components: {
        AppLayout: () => getComponent('common/AppLayout'),
        RigSchedule: () => getComponent('schedule/RigSchedule'),
		ComponentDetails: () => getComponent('schedule/ComponentDetails'),
		AddSchedule: () => getComponent('schedule/AddSchedule'),
		Multiselect,
    },
})

export default class ScheduleV2 extends mixins() {

	addJobGroupSelected = ''

	fieldNameSelection = ''

	templateDetailsFieldIndexExpanded = []

  fieldSelection = ''
  addNewWorkspacePopupAddField = false



	get activeComponent() {
		return scheduleModule.activeComponent
	}

	get activeColumns() {
		return scheduleModule.activeComponentResponseColumns
	}

  get fieldSelectionOptions() {
	  var results: any[] = []
	  for (var y = 0; y < scheduleModule.columnMapping.length; y++) {
		  if (scheduleModule.columnMapping[y].Enabled) {
			  results.push(scheduleModule.columnMapping[y].ColumnName)
		  }
		  
	  }
	  return results
  }




    get enabledWells() {
        return assetsModule.enabledWells;
    }



    async created() {

    }





  setTemplateDetailsFieldIndexExpandedIcon(indexPassed) {
	  // @ts-ignore
	  if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
		  // @ts-ignore
		const index = this.templateDetailsFieldIndexExpanded.indexOf(indexPassed);
		if (index > -1) {
			this.templateDetailsFieldIndexExpanded.splice(index, 1);
		}
	  } else {
		  // @ts-ignore
		  this.templateDetailsFieldIndexExpanded.push(indexPassed)
	  }
  }



  templateDetails = [
	  {
		  type: "Fields",
		  values: [
	
		  ]
	  },{
		  type: "Rules",
		  values: [
			  
		  ]
	  }
  ]

  applyNewWorkspacePopupAddField() {
	  
	if (this.fieldSelection != '' && this.fieldNameSelection != '') {
		this.$emit("apply-new-workspace-popup-add-field", this.fieldSelection, this.fieldNameSelection)
	}
	this.fieldSelection = ''
	this.fieldNameSelection = ''
  }



  getTemplateDetailsFieldIndexExpanded(indexPassed) {
	  // @ts-ignore
	  if (this.templateDetailsFieldIndexExpanded.includes(indexPassed)) {
		  return true
	  }
	  return false
  }

	closeAddNewWorkspacePopupAddField() {
		this.$emit('close-add-new-field-popup')
	}

  getColumnTypeIcon(column_type) {
	  if (column_type == "WELL") {
		  return "task"
	  } else if (column_type == "USER") {
		  return "person"
	  } else if (column_type == "VENDOR") {
		  return "engineering"
	  } else if (column_type == "CHECKLIST") {
		  return "checklist"
	  } else if (column_type == "FILE") {
		  return "picture_as_pdf"
	  } else if (column_type == "TESTERS") {
		  return "quiz"
	  } else if (column_type == "TASQ_DATE_RANGE") {
		  return "date_range"
	  } else if (column_type == "COST") {
		  return "attach_money"
	  } else if (column_type == "TAGS") {
		  return "tag"
	  } else if (column_type == "TEXT") {
		  return "format_size"
	  } else if (column_type == "DATE") {
		  return "calendar"
	  } else if (column_type == "PROCEDURE") {
		  return "task"
	  } else if (column_type == "EMAIL") {
		  return "email"
	  } else if (column_type == "PHONE_NUMBER") {
		  return "phone"
	  } else if (column_type == "STATUS") {
		  return "task_alt"
	  } else {
		  return "task"
	  }
  }




  get availableUsers() {
    return accountModule.reassignmentList.map((i) => ({
      // @ts-ignore
      text: i.name,
      value: i.email,
    }));
  }


  addJobColumns() {

	  if (this.addJobGroupSelected == '') {
		  return
	  }

	var groupSelected;
	// @ts-ignore
	  for (var x = 0; x < workspaceModule.activeTable.Groups.length; x++) {
		  // @ts-ignore
		  if (workspaceModule.activeTable.Groups[x].GroupName == this.addJobGroupSelected) {
			  // @ts-ignore
			  groupSelected = workspaceModule.activeTable.Groups[x]
		  }
	  }

	  var columns = groupSelected.Columns
	  return columns

  }




}




